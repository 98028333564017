// todo what makes it all work, double check the style @patrick
@import 'assets/styles/mixins';

.menu {
  // border-right: 1px solid $gray-border;
  box-sizing: content-box;
  z-index: 11;

  @at-root {
    @media (min-width: 1435px) {
      :global(.settings__fixedWidth) .menu {
        border-left: 1px solid $gray-border;
      }
    }
  }

  :global(.ant-layout-sider-children) {
    position: fixed;
    height: 100%;
    width: 256px;
    transition: width 0.3s;
  }

  &:global(.ant-layout-sider-collapsed) {
    :global(.ant-layout-sider-children) {
      width: 80px;
    }
  }

  :global(.ant-menu-inline-collapsed-tooltip) .icon {
    display: none;
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    top: 10px !important;
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    transition: padding 0s !important;
    font-weight: 300;
  }
}

.logo {
  height: 64px;
  background: var(--primary);
}

.logoContainer {
  height: 64px;
  padding-top: 10px;
  background: $white;
  img {
    width: 230px;
    display: block;
    margin: 0 auto;
  }
}

.logoContainerCollapsed {
  height: 64px;
  padding-top: 5px;
  background: $white;
  img {
    width: 55px;
    margin: 0 auto;
    display: block;
  }
}

.icon {
  text-align: center;
  position: absolute;
  right: 18px;
  width: 18px;
  top: 12px;
  margin: 0 !important;
  line-height: 1 !important;
  color: $white;
}

.navigation {
  > :global(.ant-menu-item-divider) {
    margin: 90px 23px 0 !important;
    height: 5px;
    background-color: $white;
  }

  :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
    right: 50px;
  }

  :global(.ant-menu-inline, .ant-menu-vertical),
  :global(.ant-menu-vertical-left) {
    border-right: none;
  }

  :global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
    box-shadow: none !important;
    transition: all 0.3s;
  }

  &:global(.ant-menu-inline-collapsed) {
    .title {
      display: none;
    }

    :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      display: block;
      opacity: 0.2;
      top: 48%;
      right: 14px;
    }

    .icon {
      right: 30px;
    }
  }

  &:global(.ant-menu-light) {
    .icon {
      opacity: 0.8;
    }

    :global(.ant-menu-submenu-arrow) {
      opacity: 0.7;
    }

    :global(.ant-menu-item:after) {
      border-right: 0 solid $white !important;
      right: auto;
      left: 0;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      width: 100%;
    }
  }

  &:global(.ant-menu-dark) {
    color: $white;

    :global(.ant-menu-item-divider) {
      opacity: 1;
    }

    :global(.ant-menu-sub) {
      background: transparent !important;
      color: $gray-darken;
    }

    :global(.ant-menu-item) {
      color: $white;

      > a {
        color: $white;
        &:hover {
          .icon {
            color: $white !important;
          }
        }
      }
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu) {
      text-transform: uppercase;
    }

    :global(.ant-menu-item-selected) {
      > a {
        color: $white;

        .icon {
          color: $white !important;
        }
      }
    }

    :global(.ant-menu-submenu:hover),
    :global(.ant-menu-submenu-selected),
    :global(.ant-menu-submenu-open) {
      .icon {
        color: $white !important;
      }
    }
// color for selected item in collapsed menu
    :global(.ant-menu-submenu-active) {
      .icon {
        color: $porange !important;
      }
    }

    :global(.ant-menu-inline.ant-menu-sub) {
      box-shadow: none !important;
      transition: all 0.3s;
    }

    :global(.ant-menu-item:after) {
      border-right: 0 solid $white !important;
      right: auto;
      left: 0;
    }
  }
  :global(.ant-menu-item) {
    &:global(.ant-menu-item-selected) {
      background-color: $primary !important;
      color: $white;
      border-radius: rem(10);
      a {
        color: $white;
      }
    }
  }

  :global(.active) {
    font-weight: 700;
  }
}

.scrollbarDesktop {
  height: calc(100vh - 112px) !important;
  background: $secondary-color;

  :global(.ant-input-suffix) {
    display: none;
  }

  > div:first-child {
    background: $secondary-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.scrollbarMobile {
  height: calc(100vh - 67px) !important;
}

.light {
  background: $white;

  :global(.ant-layout-sider-trigger) {
    color: inherit;
    background: var(--primary);
  }

  :global(.ant-menu-item) {
    color: $text;
    &:hover {
      a {
        color: $white;
      }
      background-color: var(--primary);
    }
  }

  :global(.drawer-content) {
    background: $white !important;
  }
}

:global(.ant-menu-inline-collapsed-tooltip) [class*='style__icon'] {
  display: none;
}

.searchField {
  color: $white;
  display: block;
  margin-bottom: 16px;
}

.title {
  color: $white;
}

.activeParent {
  font-weight: 700;
}

.menuSearchWrapper {
  margin: 24px;
}
