@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/* LIST GROUP */

.list-group-item {
  border-color: $border;

  &.active {
    background: $primary;
  }
}

// dark theme
[data-kit-theme='dark'] {
  .list-group-item {
    border-color: $dark-gray-4;
    background: $dark-gray-6;
    color: inherit;

    &.active {
      background: $primary !important;
    }

    &-action {
      color: $white !important;
      &:hover {
        background: $dark-gray-5;
      }
      &.disabled {
        opacity: 0.5;
        background: transparent;
      }
    }
  }
}
