@import 'assets/styles/mixins.scss';
// changed to 20 from 40 to 30
.content-spacing {
  padding: 30px;
}
// line at top of card
.formHeadingBorder {
  border-bottom: solid $pdgray 4px;
}
// this is line across top of cards
.h2Border {
  border-bottom: solid $pdgray 4px;
}
// line in middle of card
.h3Border:after {
  content: '';
  display: block;
  width: 100%;
  border-bottom: 3px $pdgray solid;
}

li {
  list-style-type: none;
}
// not sure?
.selectedTemplate {
  border: 1px solid green !important;
  // not sure
  span {
    display: block !important;
    position: absolute;
    bottom: -8px;
    right: -8px;
    color: green;
  }
}
