@import 'assets/styles/mixins.scss';

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $blue-lighten !important;
  border-color: $blue-lighten !important;
}

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $blue-lighten !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: $blue-lighten !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $blue-lighten !important;
}
