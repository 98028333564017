@import 'assets/styles/mixins.scss';

.ant-dropdown-menu-item:hover {
  color: white;
  background-color: var(--kit-color-primary) !important;
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  // background: var(--secondary) !important;
  background-color: transparent;
  // @include nav-gradient;

  .ant-menu-item {
    color: $white;
  }
}

.ant-layout-sider {
  background: var(--secondary);
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-rightTop {
  background-color: $green-1;
}

// polish