@import 'assets/styles/mixins.scss';

// scss to change the field border color on hover
.ant-select-selection:hover {
  border: 1px solid var(--primary-light) !important;
  box-shadow: none !important;
}

// scss to change the filed border color when select field is open
.ant-select-open .ant-select-selection {
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
}

.ant-select-dropdown-menu-item-active {
  background-color: var(--primary-light) !important;
  box-shadow: none !important;
}

.ant-select .ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
}

// scss to change the text color of multiple select field
.ant-select-selection--multiple .ant-select-selection__choice {
  color: $black !important;
}
