@import 'assets/styles/mixins.scss';

.ant-pagination-item {
  background: $white !important;
  color: $black !important;
  border: 1px solid $gray-7 !important;
}

.ant-pagination-item-active {
  background-color: $secondary !important;
  a {
    color: $white !important;
    &:hover {
      color: $white !important;
    }
  }
}
.ant-pagination-item:hover a {
  color: $white !important;
}
.ant-pagination-item:hover {
  background-color: $secondary !important;
  color: $white !important;
  border: 1px solid $primary !important;
}
.ant-pagination-item:focus {
  background-color: $secondary !important;
  color: $white !important;
  border: 1px solid $primary !important;
}
.ul {
  padding-inline-start: 10px;
  margin-left: -40px;
}

.ant-pagination-prev:active .ant-pagination-item-link,
.ant-pagination-next:active .ant-pagination-item-link,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  background-color: $prospera-dblue !important;
}

.ant-tabs-tab-prev-icon::before,
.ant-tabs-tab-next-icon::before {
  display: none;
}
