@import 'assets/styles/mixins.scss';

.btn-primary {
  background-color: $gray-1 !important;
  border: 1px solid var(--kit-color-primary) !important;
  border-radius: 4px !important;
  color: $black !important;
  margin: 3px !important;
  &:hover,
  &:focus,
  &:active {
    background: var(--kit-color-primary) !important;
    border: 1px solid var(--kit-color-primary) !important;
    color: $white !important;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: var(--kit-color-primary) !important;
    border: 1px solid var(--kit-color-primary) !important;
    color: $white !important;
  }
}

// .btn-gray {
//   background-color: var(--default);
//   border: 1px solid var(--default) !important;
//   border-radius: 4px !important;
//   color: $white !important;
//   margin: 3px !important;
//   &:hover,
//   &:focus,
//   &:active {
//     background: var(--default) !important;
//     border: 1px solid var(--default) !important;
//     color: $white !important;
//   }
//   &:not(:disabled):not(.disabled):active {
//     background-color: var(--default) !important;
//     border: 1px solid var(--default) !important;
//     color: $white !important;
//   }
// }

.btn-default {
  border: 1px solid var(--kit-color-primary);
  color: var(--kit-color-primary);
  margin: 3px;
  &:hover,
  &:focus,
  &:active {
    color: var(--primary-dark);
    border: 1px solid var(--primary-dark);
  }
}

.btn-danger {
  background-color: $red !important;
  border: 1px solid $red !important;
  border-radius: 4px !important;
  color: $white !important;
  margin: 3px !important;
  &:hover,
  &:focus,
  &:active {
    background: $red-more !important;
    border: 1px solid $red-more !important;
    color: $white !important;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $red-more !important;
    border: 1px solid $red-more !important;
    color: $white !important;
  }
}

.btn-warning {
  background-color: $warning !important;
  border: 1px solid $warning !important;
  border-radius: 4px !important;
  color: $white !important;
  margin: 3px !important;
  &:hover,
  &:focus,
  &:active {
    background: $warning-more !important;
    border: 1px solid $warning-more !important;
    color: $white !important;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $warning-more !important;
    border: 1px solid $warning-more !important;
    color: $white !important;
  }
}

.btn-orange {
  background-color: $orange-1;
}

.btn-blue {
  background-color: $blue-1;
}

.btn-gray {
  background-color: $gray-7;
}

.btn-gray-1 {
  background-color: $gray-7;
}

.btn-shadow {
  box-shadow: 4px 4px 2px $gray-border-lighten-more !important;
}

.btn-curved {
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 0.25px $gray-5 solid;
  padding: 3px 17px;
  cursor: pointer;
  color: $white;
  font-weight: bold;

  &:focus {
    outline: 0;
  }
  &:active {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    transform: translateY(4px);
  }
}

.addButton {
  &:hover {
    color: #428c3f;
  }
}

.plusIcon {
  color: #428c3f;
}

.btn-round {
  border-radius: 5px;
  border: 0;
  color: $white;
}
