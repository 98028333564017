// Import mixins
@import 'assets/styles/mixins.scss';

.link {
  color: $blue-lighten;
  &:hover,
  &:active {
    color: $blue-dark !important;
  }
}

.hyperlink {
  font-weight: bold;
  color: $green-1;
}
