@import 'assets/styles/mixins';

.investorNameInputWrapper {
  display: flex;
}

.investorInvitationForm {
  :global(.ant-row) {
    margin-right: 20px;
    display: block;
  }
  input {
    border-radius: 5px !important;
    background-color: #eaeaea;
  }

  h3 {
    margin-bottom: 15px;
  }

  :global(.ant-select-selection-item) {
    color: $gray-6;
  }
}

.mailingAddressWrapper {
  display: flex;
}

.formActionWrapper {
  float: right;
}

.dropdown {
  width: 202px;
}

.firstNameFieldWidth {
  width: 275px;
}

.lastNameFieldWidth {
  width: 275px;
}

.middleNameFieldWidth {
  width: 50px;
}

.disabled {
  width: 900px;
  pointer-events: none;
  opacity: 0.5;
}
