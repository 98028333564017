@import 'assets/styles/mixins';

.table {
  width: 100%;
  height: 100%;
  display: flex;
  padding: rem(40) 0;

  @media (max-width: $xl-max-width) {
    display: block;
    padding: 0;
    .item {
      border: 1px solid $gray-border;
      border-radius: 5px !important;
    }

    .itemFeatured {
      margin-top: 0;
    }
  }
}

.item {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
  height: 100%;
  border: 1px solid $gray-border;
  border-left: none;
  padding: rem(10);
  background: $white;
  &:first-child {
    border-left: 1px solid $gray-border;
    border-radius: 6px 0 0 6px;
  }
  &:last-child {
    border-radius: 0 6px 6px 0;
  }
}

.itemstd {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
  height: 100%;
  border: 1px solid $gray-border;
  border-left: none;
  padding: rem(10);
  background: #f2f4f8;
}

.itemext {
  color: $white;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
  border: 1px solid $gray-border;
  border-left: none;
  padding: rem(10);
  background: var(--kit-color-secondary);
}

.extended {
  margin: rem(-40) 0 rem(-40) rem(-1);
  padding: rem(60) rem(20);
  border-radius: 6px;
  border-left: 1px solid $gray-border !important;
  overflow: hidden;

  @media (max-width: $xl-max-width) {
    margin: 0;
  }
}

.default {
  background: $default;
  border-color: $default;
  color: $white;
}

.primary {
  background: $primary;
  border-color: $primary;
  color: $white;
}

.secondary {
  background: $secondary;
  border-color: $secondary;
  color: $white;
}

.success {
  background: $success;
  border-color: $success;
  color: $white;
}

.warning {
  background: $warning;
  border-color: $warning;
  color: $white;
}

.danger {
  background: $danger;
  border-color: $danger;
  color: $white;
}

.info {
  background: $info;
  border-color: $info;
  color: $white;
}

.header {
  padding: rem(30) 0;
}

.title {
  text-transform: uppercase;
  letter-spacing: rem(3);
  margin-top: rem(10);
}

.currency {
  display: inline-block;
  margin-top: rem(10);
  margin-right: rem(10);
  vertical-align: left;
}

.features {
  li {
    text-align: left;
    margin-bottom: rem(10);
  }
}

.footer {
  position: absolute;
  bottom: 2%;
  right: 0;
  width: 100%;
  padding: rem(50) 0 0 0;
}

.buttonColored {
  display: inline-block;

  padding: 0.57rem 1.14rem;
  color: $white;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--kit-color-secondary);

  line-height: 1.5;
  border-radius: 5px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.buttonPlain {
  display: inline-block;

  padding: 0.57rem 1.14rem;
  color: var(--secondary);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: $white;
  border: 1px solid black;

  line-height: 1.5;
  border-radius: 5px;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
