//Font family
@font-face {
  font-family: 'Shree Devanagari 714';
  src: local('Shree Devanagari 714'),
    url('../../fonts/shreeDevanagari714/ShreeDevanagari714.woff') format('woff');
}
@font-face {
  font-family: 'Permanent Marker';
  src: local('Permanent Marker'),
  url('../../fonts/permanentMarker/PermanentMarker.woff') format("woff");
}

$base-font-family: var(primary-font-family);
$primary-font-family: 'Open Sans', sans-serif;
$second-font-family: 'Shree Devanagari 714';
$third-font-family: 'Permanent Marker';

body {
  font-family: $primary-font-family;
}

// Font Size
$base-font-size: 16 !default;

// Colors

// Prospera Company Scheme


$prospera-green: #7d9c6e;
$prospera-dgreen: #748e67;
$prospera-lgreen: #A8BD9E;
$prospera-bgreen: #009444;
$prospera-orange: #FC8B20;
$prospera-primary: #748e67;
$prospera-secondary: #7d9c6e;
$prospera-lgray: #d3d3d3;
$prospera-dgray: #707070;
$prospera-blue: #0E2837;
$prospera-dblue: #262626;


// Accents Colors




// black
$all-black: #000;
$black: #141322;
$black-lighten: #222034;

// white color
$white: #fff;

// green colors
$green: #41b883;
$green-1: #7d9c6e;
$green-2: #51a629;
$green-3: #009444;

// red colors
$red: #f5222e;
$red-1: #f00;

// gray colors
$gray-1: #f2f4f8;
$gray-2: #e4e9f0;
$gray-3: #d3d3d3;
$gray-4: #c8c4db;
$gray-5: #a1a1c2;
$gray-6: #928b83;
$gray-7: #707070;
$gray-8: #262626;
$gray-9: #00000029;
$kit-gray-8: #262626;


// blue colors  * doenst look blue to me
$blue-1: #57657b;
$blue-2: #323c47;
$blue-lighten: #2a95dc;
$blue: #2091fc;
$blue-light: #42baf9;
$blue-dark: #2c60e4;

// orange colors
$orange-1: #fc8b20;
$orange-dark: #fc8b20;
$orange-light: #ffa617;
$orange: #faad15;

// other colors
$light-pink: #bf83a0;
$pink: #fd3995;
$purple: #652eff;
$yellow: #ff0;



// Font Colors
$text: $gray-7;
$text-darken: #615d7c;
$text-lighten: #c0bdd0;
$lighter-font: #637280;
$text-darken-more: #131a25;

// App Colors
$red-more: #dc3545;
$gray: #d2d9e5;
$menuBg-darken: #000c17;
$warning-more: #eb8519;
$primary-dark: #2a8f0c;
$hlink: #00e;
$hvisited: #551A8B;
$gray-border: #e4e9f0;
$gray-border-lighten: #c0c0c0;
$gray-border-lighten-more: #dcdcdc;
$gray-darken: #b8beca;
$gray-lighten-more: #f2f4f8;
$app-brand-color: var(--primary);

// dark theme
$dark-gray-1: #d3d3d3;
$dark-gray-2: #a6a6a6;
$dark-gray-3: #9a9a9a;
$dark-gray-4: #808080;
$dark-gray-5: #676767;
$dark-gray-6: #5a5a5a;
$dark-gray-7: #3b3b3b;
$dark-gray-8: #0c0c1b;



// App Colors

$border: $white;
$default: $gray-4;
$primary: var(--kit-color-primary);
$secondary: var(--kit-color-secondary);
//
$secondary-color: var(--kit-color-secondary);
$secondary: $prospera-blue;
// $secondary: $gray-5;
$success: $green;
$info: $blue-light;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;
$pblue: $prospera-blue;
$pgreen: $prospera-green;
$pdgreen: $prospera-dgreen;
$plgreen: $prospera-lgreen;
$pdgreen: $prospera-bgreen;
$porange: $prospera-orange;
$pdgray: $prospera-dgray;
$pdblue: $prospera-dblue;
// $hlink: #00E;
$hvisited: #551A8B;


// https://material.io/design/environment/elevation.html#default-elevations

// Gradients

@mixin nav-gradient {
  background-image: linear-gradient(
    194deg,
    #145629 0%,
    #22652a 6%,
    #3b802c 20%,
    #4f952d 35%,
    #5da42f 51%,
    #65ad2f 70%,
    #68b030 100%
  );
}

// gradient button color mixin
@mixin button-gradient {
  background-image: linear-gradient(180deg, $orange-dark 0%, $orange-light 100%);
}

// Shadows
// Shadows
// made them all the same since there is an issue with css
$shadow: 0 10px 6px -6px $gray-3;
$shadow-2: 0 10px 6px -6px $gray-3;
$shadow-3: 0 10px 6px -6px $gray-3;
$shadow-4: 0 10px 6px -6px $gray-3;
$shadow-5: 0 10px 6px -6px $gray-3;
$shadow-ant: 0 10px 6px -6px $gray-3;

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // @return #{$px}px; // to PX's
}



// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}



// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;
