// Heading typography

h1 {
  text-indent: 0;
  font-size: 1.85rem;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
}

h2 {
  text-indent: 0;
  font-size: 1.42rem;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
  margin-bottom: 0;
}

h3 {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  overflow: hidden;
  margin-bottom: 0;
}

 h4 {
  font-size: 1rem;
  font-family: $second-font-family;
  overflow: hidden;
  margin-bottom: 0;
}

h5 {
  font-size: .8rem;
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
}

h6 {
  font-size: 1.7rem;
  font-weight: 400;
  font-family: $primary-font-family;
  overflow: hidden;
}

h7 {
  font-size: 2rem;
  font-weight: 450;
  font-family: $third-font-family;
  overflow: hidden;
}

h15 {
  font-size: 1.65em;
  font-weight: 500;
  text-transform: uppercase;
  overflow: hidden;
}

// Paragraph typography
p {
  font-size: 1rem;
  font-weight: 300;
  font-family: $primary-font-family;
  letter-spacing: -0.2;
}

.p-dark {
  color: $gray-3;
}

.p-dark-bold {
  color: $gray-3;
  font-weight: bold;
}

.p-light {
  color: $blue-2;
}

.p-light-bold {
  color: $blue-2;
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

// Table typography
table {
  thead tr th {
    font-size: 1rem;
    font-weight: 300 !important;
  }

  tbody tr td {
    font-size: 1rem;
  }
}

// Button typography
button {
  font-size: 1.2rem;
}

// Link typography
a {
  font-size: 1rem;
}

// menu typography
.ant-menu-item {
  font-size: 1.1rem;
}

.ant-menu-inline .ant-menu-submenu-title {
  font-size: 1.1rem;
}

// breadcrumb typography
.breadcrumbPath {
  font-size: 1rem;
}
