// Import mixins
@import 'assets/styles/mixins.scss';

.ant-btn {
  &:hover,
  &:active,
  &:focus {
    border-color: lighten($pdgreen, 0%);
    color: lighten($plgreen, 0%);
  }
  &-primary {
    background: $prospera-green !important;
    border-color: $prospera-dgreen !important;

    &:hover,
    &:active,
    &:focus {
      background: lighten($prospera-dgreen, 10%) !important;
      border-color: lighten($prospera-dgreen, 0%) !important;
      color: $white;
    }
  }
  &-pblue {
    background: $prospera-blue !important;
    border-color: $prospera-blue !important;

    &:hover,
    &:active,
    &:focus {
      background: lighten($prospera-blue, 20%) !important;
      border-color: lighten($prospera-blue, 0%) !important;
      color: $white;
    }
  }
  &-porange {
    background: $prospera-orange !important;
    border-color: $prospera-orange !important;

    &:hover,
    &:active,
    &:focus {
      background: lighten($prospera-orange, 5%) !important;
      border-color: lighten($prospera-orange,0%) !important;
      color: $white;
    }
  }
  &-pdgray {
    background: $prospera-dgray !important;
    border-color: $black !important;

    &:hover,
    &:active,
    &:focus {
      background: lighten($prospera-dgray, 10%) !important;
      border-color: lighten($black, 0%) !important;
      color: $white;
    }
  }

  &-danger {
    &:hover,
    &:active,
    &:focus {
      background: lighten($danger, 25%);
      border-color: lighten($danger, 0%);
      color: $white;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-btn {
    &-primary {
      &:hover,
      &:active,
      &:focus {
        background: lighten($blue-light, 0%) !important;
        border-color: lighten($blue-light, 0%) !important;
        color: $white;
      }
    }
    &-danger {
      &:hover,
      &:active,
      &:focus {
        background: lighten($danger, 10%);
        border-color: lighten($danger, 10%);
        color: $white;
      }
    }
  }
}
