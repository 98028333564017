@import './src/assets/styles/mixins';


.btngray {
  background-color: #0e2837;
  position: absolute;
  right: 5px;
  bottom: 0;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #3e4857;
  border-style: solid;
  padding: 4px;
  cursor: pointer;
  color: $white;
  box-shadow: 4px 4px 2px $gray-border-lighten-more !important;

  &:focus {
    outline: 0;
  }
}
