// import mixins

// i think this is the one getting used
@import 'assets/styles/mixins.scss';

.cardBody {
  background-color: $gray-4;
  padding: 5px 10px 5px 10px;
  max-height: max-content;
}

.noPadCard {
  border-radius: 5px;
  padding: 10px 0;
  border: 0;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background: $white;
  margin-top: 0;
  position: relative;
  height: 100%;
  border: #false;

  &__cardBorder {
    border-radius: 12px;
  }
}

.idealCard {
  border-radius: 5px;
  padding: 12px;
  border: 0;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background: $white;
  margin-top: 0;
  position: relative;
  height: 100%;
  border: #false;


  &__cardBorder {
    border-radius: 12px;
  }

}
.graphCard {
  border-radius: 5px;
  padding: 12px;
  border: .5px solid $pdgray;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background: $white;
  margin-top: 0;
  position: relative;
  height: 50%;

  &__cardBorder {
    border-radius: 12px;
  }
}



.chartGraphCard {

}

.chartWidgetCard {
  height: 340px;
}

.noChartWidgetCard {
  position: relative;
  height: 200px;
  text-align: center;
}

.idealCardBody {
  padding-top: 8px;
  background-color: transparent;
}

.idealCardNoShadow {
  border: .5px dot-dash $pdgray;
  border-radius: 10px;
  padding: 15px 20px;
}

.idealCardCurved {
  position: relative;
  border-radius: 10px 0;
  box-shadow: 12px 12px 2px $gray-3;
}

.dashboardCard {
  position: relative;
  height: 304px;
}




.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.circle{
  width:150px;height:150px;
  border: solid 1px #555;
  background-color: #eed;
  box-shadow: 10px -10px rgba(0,0,0,0.6);
  -moz-box-shadow: 10px -10px rgba(0,0,0,0.6);
  -webkit-box-shadow: 10px -10px rgba(0,0,0,0.6);
  -o-box-shadow: 10px -10px rgba(0,0,0,0.6);
  border-radius:100px;
}

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-card {
    color: $dark-gray-1;
    &-meta {
      &-description {
        color: $dark-gray-1;
      }
    }

    &-actions {
      background: $dark-gray-6;

      > li {
        .anticon {
          color: $dark-gray-1;
        }
      }
    }
  }
}
