@import 'assets/styles/mixins.scss';

.previewContainer {
  padding: 10px;
  margin: 10px 10px 10px -5px;
  width: 80%;
}

.subscriptionSection {
  margin-bottom: 10px;
  word-wrap: break-word;
}

.formLayout {
  width: 80%;
  min-width: 558px;
  margin-bottom: 20px !important;
}

.investorInvitationWrapper {
  width: 900px;
}

.marginBottom {
  margin-bottom: 16px;
}

.investorInvitationForm {
  // :global(.ant-row) {
  //   margin-right: 20px;
  //   display: block;
  // }
/*
  input {
    border-radius: 5px !important;
  }
*/
  :global(.ant-select-selection-item) {
    color: $gray-6;
  }
}

.existingInvestorFormWrapper {
  display: flex;

  :global(.ant-select-selector) {
    width: 191px !important;
  }
}

.expectedContributionInput {
  width: 200px;
}

// .titleInput {
//   input {
//     width: 97px !important;
//   }
// }

.taxInput {
  input {
    display: flex;
    justify-content: flex-end;
    width: 200px !important;
  }
}

.advisorFieldInput {
  :global(.ant-select) {
    width: 143px !important;
  }
}

.otherContactsFieldsWrapper {
  display: flex;
}

.formActionWrapper {
  display: flex;
  justify-content: space-between;
}

.saveButton {
  margin-right: 20px;
}

.previousButton {
  position: absolute;
  left: 20px;
}

.addButton {
  &:hover {
    color: #428c3f;
  }
}

.plusIcon {
  color: #428c3f;
}

.removeButton {
  &:hover {
    color: #ff0001;
  }
}

.minusIcon {
  color: #ff0001;
}
