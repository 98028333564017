// Import mixins
@import 'assets/styles/mixins.scss';

.ant-input {
  &:hover {
    border-color: var(--kit-color-primary) !important;
  }
  &:focus {
    border-color: var($orange-1) !important;
  }
}

.has-error .ant-input:focus {
  border-color: $red !important;
}

.middleInitialFieldWidth {
  width: 50px;
}

.taxInput {
  width: 140px;
}

.mailingAddressFieldWidth {
  width: 313px;
}

.zipCodeFieldWidth {
  width: 140px;
}

.stateFieldWidth {
  width: 140px;
}

.cityFieldWidth {
  width: 140px;
}

.countryFieldWidth {
  width: 140px;
}

.relationshipFieldWidth {
  width: 140px;
}
