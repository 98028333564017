// Import mixins
@import 'assets/styles/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-checkbox {
    &-inner {
      background-color: $gray-6;
    }
    &-checked {
      .ant-checkbox-inner {
        background: $primary;
      }
    }
    &-disabled {
      .ant-checkbox-inner {
        border-color: $dark-gray-4 !important;
        opacity: 0.3;
      }
    }
  }
}
