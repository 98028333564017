@import 'assets/styles/mixins';

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
}

.login {
  background-image: url(/resources/images/prospera.png);
}

.signup {
  background-image: url(/resources/images/prospera-fund-investment.png);
}

.block {
  padding: rem(20) rem(20) rem(20);
  width: 100%;
  align-self: center;
  position: relative;
  z-index: 2;

  @media (max-width: $md-max-width) {
    padding: rem(20);
  }
}

.inner {
  min-width: rem(300);
  max-width: rem(500);
  margin: 0 auto;
  border-radius: 15px;
  overflow: hidden;
  background-color: $white;
  position: relative;
  // border: 1px solid $gray-border-lighten;

  @media (max-width: $md-max-width) {
    padding: rem(30) rem(30) rem(30);
  }
}

.loginInner {
  padding: rem(30) rem(30) rem(30);
}

.signupInner {
  padding: rem(30) rem(30);
}

.header {
  align-self: flex-start;
  width: 100%;
  color: $white;
  padding-top: rem(120);

  @media (max-width: $md-max-width) {
    padding: rem(40) rem(20) 0 rem(40);
  }
}

.logo {
  img {
    min-width: 23.07rem;
    max-width: 38.46rem;
    margin: 0 auto;
    display: block;
  }
}

/* .menu {
  text-align: right;
  margin-top: rem(10);

  @media (max-width: $md-max-width) {
    text-align: left;
  }

  ul {
    font-size: rem(16);
    li {
      margin-right: rem(20);
      text-transform: uppercase;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        a {
          border-bottom: 1px solid rgba($white, 0.5);
        }
      }
      a {
        color: $white !important;
        line-height: 1.4;
        display: inline-block;
        margin-right: rem(10);
        font-weight: bold;
        &:hover {
          border-bottom: 1px solid rgba($white, 0.5);
        }
      }
    }
  }
} */

.content {
  align-self: middle;
}

.footer {
  align-self: flex-end;
  width: 100%;
  opacity: 1;
  //color: $text;
  color: $white;
  padding: rem(40);

  @media (max-width: $md-max-width) {
    padding: rem(40) rem(20) rem(40);
  }

  ul {
    margin-bottom: 0;
    li {
      margin-right: rem(20);
      text-transform: uppercase;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: $white;
        opacity: 1;
        line-height: 1.4;
        display: inline-block;
        margin-right: rem(10);
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
