@plugin 'AntdThemeLoader';
apply("prospera");

// these are pretty worthless since they don't really change much. We need scss that actually does something. this should just be called colors

[data-kit-theme="prospera"] {
  @import '../../../../../../../node_modules/antd/lib/style/components.less';

  @kit-white: #ffffff;
  @kit-black: #141322;
  @kit-gray-1: #f2f4f8;
  @kit-gray-2: #e4e9f0;
  @kit-gray-3: #d3d3d3;
  @kit-gray-4: #c8c4db;
  @kit-gray-5: #a1a1c2;
  @kit-gray-6: #928b83;
  @kit-gray-7: #707070;
  @kit-gray-8: #262626;
  @kit-gray-9: #323C47;
  @kit-blue: #4b7cf3;
  @kit-blue-light: #42baf9;
  @kit-red: #f5222e;
  @kit-green: #41b883;
  @kit-green-1: #7d9c6e;
  @kit-orange: #faad15;
  @pgreen: @prospera-primary;
  @pdgreen: @prospera-secondary;
  @porange: @prospera-orange;
  @pblue:  @prospera-blue;
  @plgray: @prospera-lgray;
  @pdgray: @prospera-dgray;

  @hlink: #0000EE;
  @hvisited: #551A8B;
  @prospera-blue: #0E2837;
  @prospera-orange: #FC8B20;
  @prospera-primary: #748e67;
  @prospera-secondary: #7d9c6e;
  @prospera-lgray: #d3d3d3;
  @prospera-dgray: #707070;
  @hlink: #0000EE;
  @hvisited: #551A8B;
  @kit-blue-light: #42baf9;

  @kit-green: #41b883;
  @kit-orange: #faad15;

  // @text-color: @kit-gray-7;
  @primary-color: @kit-green-1;
  @info-color:  @kit-green-1;
  @success-color: @kit-green;
  @processing-color:  @kit-green-1;
  @error-color: @kit-red;
  @highlight-color: @kit-red;
  @warning-color: @kit-orange;
  @normal-color: @kit-gray-3;

  @body-background: @kit-white;
  @component-background: @kit-white;
  @heading-color: @kit-gray-6;

  @layout-body-background: transparent;
  @layout-header-background: transparent;
  @layout-footer-background: transparent;

  // @border-color-base: @kit-gray-2;
  // @border-color-split: @kit-gray-2;
  @border-color-inverse: @kit-white;
  // '@border-radius-base: 2px;
  @disabled-color: @kit-gray-4;
  @disabled-bg: @kit-gray-2;


  // @btn-primary-color: @kit-white;
  // @btn-primary-bg:  @kit-green-1;
  // @btn-default-color: @kit-gray-6;
  @btn-default-bg: @kit-white;
  @btn-danger-color: @kit-white;
  @btn-danger-bg: @kit-red;
  @btn-danger-border: @kit-red;

  @link-color:  @kit-green-1;
  @link-hover-color: @kit-blue-light;
  @link-active-color: @kit-blue-light;

  @item-active-bg: @kit-gray-2;
  @item-hover-bg: @kit-gray-2;

  @input-placeholder-color: lighten(@kit-gray-6, 10%);
  @input-color: @kit-gray-7;
  @input-addon-bg: @kit-gray-1;
  @input-bg: @kit-white;
  @input-border-color: @kit-gray-2;

  @alert-success-border-color: #b7eb8f;
  @alert-success-bg-color: #f6ffed;
  @alert-success-icon-color: @kit-green;
  @alert-info-border-color: #91d5ff;
  @alert-info-bg-color: #e6f7ff;
  @alert-info-icon-color: @kit-green-1;
  @alert-warning-border-color: #ffe58f;
  @alert-warning-bg-color: #fffbe6;
  @alert-warning-icon-color: @kit-orange;
  @alert-error-border-color: #ffa39e;
  @alert-error-bg-color: #fff1f0;
  @alert-error-icon-color: @kit-red;

  @tabs-hover-color: @kit-blue-light;
  @tabs-active-color:  @kit-green-1;
  @tabs-card-active-color:  @kit-green-1;
  @tabs-ink-bar-color:  @kit-green-1;
  @tabs-highlight-color:  @kit-green-1;

//  @table-header-bg: @kit-gray-1;
  @table-header-bg: @kit-green-1;
// table header colors
  @table-header-color: #fff;
  @table-footer-bg: @kit-gray-1;
  @table-footer-color: @kit-gray-6;
  @table-row-hover-bg: @kit-gray-2;
  @table-selected-row-bg: @kit-gray-1;
  @table-expanded-row-bg: @kit-gray-1;

  @label-required-color: @kit-red;
  @label-color: @kit-gray-9;


  @form-item-label-font-size: 16px;
  @form-item-ant-select: 16px;
  @form-item-ant-input: 16px;
  @font-size-base: 16px;

  // LINK
  @link-color: @primary-color;
  @link-hover-color: color(~`colorPalette('@{link-color}', 5) `);
  @link-active-color: color(~`colorPalette('@{link-color}', 7) `);
  @link-decoration: none;
  @link-hover-decoration: none;
  @link-focus-decoration: none;
  @link-focus-outline: 0;


}
