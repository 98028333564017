@import '../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  "CORE" STYLES */

:root {
  --kit-color-primary:  #7d9c6e;
  --kit-color-secondary: #748e67;
  --porange: #fc8b20;
  --green: #7d9c6e;
  --pgreen: #7d9c6e;
  --plgreen: #A8BD9E;
  --pdgreen: #748e67;
  --pbgreen: #009444;
  --plgray: #d3d3d3;
  --pdgray: #707070;
  --pblue: #0e2837;
  --pdblue: #262626;
  --background-overlay-top: #fff;
  --background-overlay-bottom: #fff;
  --background-opacity: 0;
  --kit-font-family: 'Open Sans', 'sans-serif';
  --second-font-family: 'Shree Devanagari 714';
  --third-font-family: 'Permanent Marker, cursive';
}

html {
  font-size: 1rem;
  @media (max-width: $sm-max-width) {
    font-size: 1rem;
  }
}

body {
  font-size: 1rem;
  line-height: 1.5;
  font-family: $base-font-family;
  color: $text;
  overflow-x: hidden;
  position: relative;
  font-variant: normal;
  font-feature-settings: normal;
}

a {
  text-decoration: none;
  color: $text;
  @include transition-color();
  &:hover,
  &:active,
  &:focus {
    color: $blue;
    text-decoration: none;
  }
}

input {
  outline: none !important;
  font-family: $base-font-family;
  color: $text;
}

button,
input {
  box-shadow: none !important;
  outline: none !important;
}

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  appearance: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h15,


// no clue what this is
// {
//    color: #0E2837 !important;
// }

svg {
  vertical-align: initial;
  overflow: auto;
}

.badge-example {
  font-size: rem(14);
  text-transform: uppercase;
  margin-bottom: rem(15);
  background: $gray-2;
  // background: $dark-gray-4;
  color: $black;
  display: inline-block;
  padding: rem(3) rem(6);
  border-radius: 4px;
}


// dark theme
[data-kit-theme='dark'] {
  body {
    background: $dark-gray-5;
    color: $dark-gray-1;
  }
}


