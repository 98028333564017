@import './src/assets/styles/mixins.scss';


.bottomLine {
  padding: 0.5vw;
  vertical-align: bottom;
  border-bottom: solid 5px #ddd;
}

.title {
  margin-top: 3%;
}

// Form core


.content {
  text-align: right;
}


.textBlock {
  font-size: 1rem;
  text-align: left;
  margin: 16px 0 0 10px;
}

.mainCard {
  padding: 16px;
  width: auto;
  height: auto;
  overflow: auto;
}

.bttmrow {
  margin: 50px 0 0 380px;
}

  :global(.ant-form-item) {
    display: flex;
  }

  :global(.ant-form-item-label) {
    min-width: 105px;
    // float: left;
    white-space: nowrap;
  }

  :global(.ant-form-item-control-wrapper) {
    // float: left;
    // width: calc(100% - 105px);
    width: 100%;
  }

  :global(.ant-col.ant-form-item-control) {
    margin-right: 10px;
  }

  .customLabelMin {
    :global(.ant-form-item-label) {
      min-width: 100px;
      font-size: 16px;
    }
  }

  .customLabelMax {
    :global(.ant-form-item-label) {
      min-width: 170px;
      font-size: 1rem;
    }
  }

