// labels on forms should be h3 or 1 remove
// patrick
@import 'assets/styles/mixins.scss';

[data-kit-theme='prospera'] .ant-form-item {
  margin-bottom: 5px;
  font-size: 16px;
}

[data-kit-theme='prospera'] .ant-form-item-label {
  text-align: left;
  font-size: 16px;
}

[data-kit-theme='prospera'] .ant-form-item-control-input-content {
  display: flex;
  margin-bottom: 10px;
  margin-left: 10px;
//  text-align: right;
  // This could affect a lot
  // &:last-child {

  // }
}

.ant-form label {
  font-size: 1rem;
}

.formLayout {
  width: 80%;
  min-width: 558px;
  margin-bottom: 20px !important;
}

.hasError {
  border: 1px solid red !important;
}

.stretchSelectField {
  width: 100%;
}
